/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */
import type { ReactNode } from 'react';
import { startTransition, StrictMode, useMemo, useState } from 'react';
import { hydrateRoot } from 'react-dom/client';

import { RemixBrowser } from '@remix-run/react';

import CssBaseline from '@mui/material/CssBaseline';

import ClientStyleContext from './providers/client-style-context';
import createEmotionCache from './utils/create-cache';
import BugsnagPerformance from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { CacheProvider } from '@emotion/react';

const isProd = process.env.NODE_ENV !== 'development';
if (isProd) {
  Bugsnag.start({
    apiKey: '3bc47bcc6b6230ff8e0761ab910f3482',
    plugins: [new BugsnagPluginReact()],
  });
  BugsnagPerformance.start({ apiKey: '3bc47bcc6b6230ff8e0761ab910f3482' });
}

interface ClientCacheProviderProps {
  children: ReactNode;
}
function ClientCacheProvider({ children }: ClientCacheProviderProps) {
  const [cache, setCache] = useState(createEmotionCache());

  const clientStyleContextValue = useMemo(
    () => ({
      reset() {
        setCache(createEmotionCache());
      },
    }),
    [],
  );

  return (
    <ClientStyleContext.Provider value={clientStyleContextValue}>
      <CacheProvider value={cache}>{children}</CacheProvider>
    </ClientStyleContext.Provider>
  );
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <ClientCacheProvider>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <RemixBrowser />
      </ClientCacheProvider>
    </StrictMode>,
  );
});
